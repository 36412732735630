import { withAuthenticationRequired } from "@auth0/auth0-react";
import LoadingPage from "../auth/LoadingPage";
import Background from "../components/Background";
import { Loading } from "../components/Loading";
import { Box } from "@mui/system";

const TestPage = () => {
  return (
    <Background>
      <Box
        sx={{
          height: 1,
          width: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loading />
      </Box>
    </Background>
  );
};

export default withAuthenticationRequired(TestPage, {
  onRedirecting: () => <LoadingPage />,
});
