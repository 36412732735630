import Background from "../components/Background";
import { Box } from "@mui/system";
import { Loading } from "../components/Loading";

const LoadingPage = () => {
  return (
    <Background>
      <Box
        sx={{
          height: 1,
          width: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loading />
      </Box>
    </Background>
  );
};

export default LoadingPage;
