import {
  Announcements,
  DndContext,
  DragEndEvent,
  DragMoveEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  DropAnimation,
  KeyboardSensor,
  MeasuringStrategy,
  Modifier,
  PointerSensor,
  closestCenter,
  defaultDropAnimation,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useEffect, useMemo, useRef, useState } from "react";
import VirtualList from "react-tiny-virtual-list";

import { useBBMutation } from "../hooks/useBBMutation";
import { Block, Blocks, CollapseUserSettings } from "../types";

import { createPortal } from "react-dom";
import {
  useCollapseUserSettingsCreate,
  useCollapseUserSettingsDelete,
} from "../hooks/useCollapseUserSettings";
import { SortableBuildingBlockListItem } from "./SortableBuildingBlockListItem";
import {
  FlattenedItem,
  SensorContext,
  UniqueIdentifier,
  buildTree,
  flattenTree,
  getChildCount,
  getProjection,
  removeChildrenOf,
  removeItem,
  setProperty,
  sortableTreeKeyboardCoordinates,
} from "./utils";

const measuring = {
  droppable: {
    strategy: MeasuringStrategy.Always,
  },
};

const dropAnimationConfig: DropAnimation = {
  keyframes({ transform }) {
    return [
      { opacity: 1, transform: CSS.Transform.toString(transform.initial) },
      {
        opacity: 0,
        transform: CSS.Transform.toString({
          ...transform.final,
          x: transform.final.x + 5,
          y: transform.final.y + 5,
        }),
      },
    ];
  },
  easing: "ease-out",
  sideEffects({ active }) {
    active.node.animate([{ opacity: 0 }, { opacity: 1 }], {
      duration: defaultDropAnimation.duration,
      easing: defaultDropAnimation.easing,
    });
  },
};

const adjustTranslate: Modifier = ({ transform }) => {
  return {
    ...transform,
    y: transform.y - 25,
  };
};

const buildTreeWithConfig = (
  blocks: Blocks,
  collapseUserSettings: CollapseUserSettings
) => {
  return buildTree(
    blocks.map((block) => ({
      ...block,
      children: [],
      collapsed: !!collapseUserSettings.find(
        (setting) => setting.blockId === block.id
      ),
    }))
  );
};

const BuildingBlockList = ({
  blocks,
  collapseUserSettings,
  handleBlockChange, // Consider moving state management to this component
  handleBlockCheckChange,
  handleBlockDueChangeChange,
  refetchBuildingBlocks,
  indentationWidth = 28,
  indicator = false,
  collapsible = true,
  removable = false,
  listHeight,
}: {
  blocks: Array<Block>;
  collapseUserSettings: CollapseUserSettings;
  handleBlockChange: Function;
  handleBlockCheckChange: Function;
  handleBlockDueChangeChange: Function;
  refetchBuildingBlocks: Function;
  indentationWidth?: number;
  indicator?: boolean;
  collapsible?: boolean;
  removable?: boolean;
  listHeight: number;
}) => {
  const mutation = useBBMutation();
  const collapseCreate = useCollapseUserSettingsCreate();
  const collapseDelete = useCollapseUserSettingsDelete();
  const [treeBlocks, setTreeBlocks] = useState(() =>
    buildTreeWithConfig(blocks, collapseUserSettings)
  );
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [overId, setOverId] = useState<UniqueIdentifier | null>(null);
  const [offsetLeft, setOffsetLeft] = useState(0);
  const [currentPosition, setCurrentPosition] = useState<{
    parentId: UniqueIdentifier | null;
    overId: UniqueIdentifier;
  } | null>(null);

  const FlatBlocks = useMemo(() => {
    const flattenedTree = flattenTree(treeBlocks);
    const collapsedItems = flattenedTree.reduce<string[]>(
      (acc, { children, collapsed, id }) =>
        collapsed && children.length ? [...acc, id] : acc,
      []
    );

    return removeChildrenOf(
      flattenedTree,
      activeId ? [activeId, ...collapsedItems] : collapsedItems
    );
  }, [activeId, treeBlocks]);

  const projected =
    activeId && overId
      ? getProjection(
          FlatBlocks,
          activeId,
          overId,
          offsetLeft,
          indentationWidth
        )
      : null;

  const sensorContext: SensorContext = useRef({
    items: FlatBlocks,
    offset: offsetLeft,
  });

  const [coordinateGetter] = useState(() =>
    sortableTreeKeyboardCoordinates(sensorContext, false, indentationWidth)
  );

  const sensors = useSensors(
    useSensor(PointerSensor, {
      // Tolerance of 5px of movement, no delay because we use a drag handle
      activationConstraint: {
        delay: 0,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter,
    })
  );

  const sortedIds = useMemo(() => FlatBlocks.map(({ id }) => id), [FlatBlocks]);

  const activeItem = activeId
    ? FlatBlocks.find(({ id }) => id === activeId)
    : null;

  useEffect(() => {
    sensorContext.current = {
      items: FlatBlocks,
      offset: offsetLeft,
    };
  }, [FlatBlocks, offsetLeft]);

  const announcements: Announcements = {
    onDragStart({ active }) {
      return `Picked up ${active.id}.`;
    },
    onDragMove({ active, over }) {
      return getMovementAnnouncement(
        "onDragMove",
        active.id.toString(),
        over?.id.toString()
      );
    },
    onDragOver({ active, over }) {
      return getMovementAnnouncement(
        "onDragOver",
        active.id.toString(),
        over?.id.toString()
      );
    },
    onDragEnd({ active, over }) {
      return getMovementAnnouncement(
        "onDragEnd",
        active.id.toString(),
        over?.id.toString()
      );
    },
    onDragCancel({ active }) {
      return `Moving was cancelled. ${active.id} was dropped in its original position.`;
    },
  };

  function handleDragStart({ active: { id: activeId } }: DragStartEvent) {
    setActiveId(activeId.toString());
    setOverId(activeId.toString());

    const activeItem = FlatBlocks.find(({ id }) => id === activeId);

    if (activeItem) {
      setCurrentPosition({
        parentId: activeItem.parentId,
        overId: activeId.toString(),
      });
    }

    document.body.style.setProperty("cursor", "grabbing");
  }

  function handleDragMove({ delta }: DragMoveEvent) {
    setOffsetLeft(delta.x);
  }

  function handleDragOver({ over }: DragOverEvent) {
    setOverId(over?.id.toString() ?? null);
  }

  async function handleDragEnd({ active, over }: DragEndEvent) {
    resetState();

    if (projected && over) {
      const { depth, parentId } = projected;
      const clonedItems: FlattenedItem[] = JSON.parse(
        JSON.stringify(flattenTree(treeBlocks))
      );
      const overIndex = clonedItems.findIndex(({ id }) => id === over.id);
      const activeIndex = clonedItems.findIndex(({ id }) => id === active.id);
      const activeTreeItem = clonedItems[activeIndex];
      const newActiveTreeItem = { ...activeTreeItem, depth, parentId };
      clonedItems[activeIndex] = newActiveTreeItem;

      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);

      const newItems = buildTree(sortedItems);

      if (
        active.id === over?.id &&
        projected?.depth === activeTreeItem?.depth
      ) {
        return;
      }
      // Update main data tree
      setTreeBlocks(newItems);

      // Save changes to the backend
      const newFlattedItems = flattenTree(newItems);

      // targetId is the new location previous item
      // If it's null, it should be the first item
      let targetId: string | undefined = undefined;

      // Root array or children array
      const children = newActiveTreeItem.parentId
        ? newFlattedItems.find((i) => newActiveTreeItem.parentId === i.id)
            ?.children
        : newFlattedItems.filter((i) => !i.parentId);

      const currentItemIndexInParent = children?.findIndex(
        (k) => k.id === newActiveTreeItem.id
      );

      if (currentItemIndexInParent) {
        targetId = children?.[currentItemIndexInParent - 1]?.id as string;
      }

      await mutation.mutate(
        {
          reorder: true,
          id: active.id as string,
          targetId: targetId as string,
          parentId: parentId,
        },
        {
          onSuccess: () => refetchBuildingBlocks(),
          onError: () => refetchBuildingBlocks(),
          onSettled: () => refetchBuildingBlocks(),
        }
      );
      refetchBuildingBlocks();
    }
  }

  function handleDragCancel() {
    resetState();
  }

  function resetState() {
    setOverId(null);
    setActiveId(null);
    setOffsetLeft(0);
    setCurrentPosition(null);

    document.body.style.setProperty("cursor", "");
  }

  function handleRemove(id: UniqueIdentifier) {
    setTreeBlocks((items) => removeItem(items, id));
  }

  function handleCollapse(id: UniqueIdentifier) {
    setTreeBlocks((items) =>
      setProperty(items, id, "collapsed", (value) => {
        if (!value) {
          collapseCreate.mutate({ blockId: id });
        } else {
          collapseDelete.mutate({ blockId: id });
        }
        return !value;
      })
    );
  }

  function getMovementAnnouncement(
    eventName: string,
    activeId: UniqueIdentifier,
    overId?: UniqueIdentifier
  ) {
    if (overId && projected) {
      if (eventName !== "onDragEnd") {
        if (
          currentPosition &&
          projected.parentId === currentPosition.parentId &&
          overId === currentPosition.overId
        ) {
          return;
        } else {
          setCurrentPosition({
            parentId: projected.parentId,
            overId,
          });
        }
      }

      const clonedItems: FlattenedItem[] = JSON.parse(
        JSON.stringify(flattenTree(treeBlocks))
      );
      const overIndex = clonedItems.findIndex(({ id }) => id === overId);
      const activeIndex = clonedItems.findIndex(({ id }) => id === activeId);
      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);

      const previousItem = sortedItems[overIndex - 1];

      let announcement;
      const movedVerb = eventName === "onDragEnd" ? "dropped" : "moved";
      const nestedVerb = eventName === "onDragEnd" ? "dropped" : "nested";

      if (!previousItem) {
        const nextItem = sortedItems[overIndex + 1];
        announcement = `${activeId} was ${movedVerb} before ${nextItem.id}.`;
      } else {
        if (projected.depth > previousItem.depth) {
          announcement = `${activeId} was ${nestedVerb} under ${previousItem.id}.`;
        } else {
          let previousSibling: FlattenedItem | undefined = previousItem;
          while (previousSibling && projected.depth < previousSibling.depth) {
            const parentId: UniqueIdentifier | null = previousSibling.parentId;
            previousSibling = sortedItems.find(({ id }) => id === parentId);
          }

          if (previousSibling) {
            announcement = `${activeId} was ${movedVerb} after ${previousSibling.id}.`;
          }
        }
      }

      return announcement;
    }
  }

  useEffect(() => {
    if (!blocks?.length) return;
    setTreeBlocks(buildTreeWithConfig(blocks, collapseUserSettings));
  }, [blocks, collapseUserSettings]);

  return (
    <DndContext
      accessibility={{ announcements }}
      sensors={sensors}
      collisionDetection={closestCenter}
      measuring={measuring}
      onDragStart={handleDragStart}
      onDragMove={handleDragMove}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <SortableContext items={sortedIds} strategy={verticalListSortingStrategy}>
        <VirtualList
          width="100%"
          height={listHeight}
          itemCount={FlatBlocks.length}
          itemSize={(index) => {
            const isFromADifferentSpace =
              index > 0
                ? FlatBlocks[index].ownerId !== FlatBlocks[index - 1].ownerId
                : false;

            return isFromADifferentSpace ? 86 : 38;
          }}
          // onScroll={(scrollTop) => {
          //   // TODO: Switch title and functions for the sticky bar
          //   console.log(scrollTop);
          // }}
          renderItem={({ index, style }) => {
            const block = FlatBlocks[index];
            return (
              <SortableBuildingBlockListItem
                key={block.id}
                id={block.id}
                block={block}
                style={style}
                flatBlocks={FlatBlocks}
                depth={
                  block.id === activeId && projected
                    ? projected.depth
                    : block.depth
                }
                indentationWidth={indentationWidth}
                indicator={indicator}
                collapsed={Boolean(block.collapsed && block.children.length)}
                onCollapse={
                  collapsible && block.children.length
                    ? () => handleCollapse(block.id)
                    : undefined
                }
                onRemove={removable ? () => handleRemove(block.id) : undefined}
                handleBlockChange={handleBlockChange}
                handleBlockCheckChange={handleBlockCheckChange}
                handleBlockDueChangeChange={handleBlockDueChangeChange}
                refetchBuildingBlocks={refetchBuildingBlocks}
              />
            );
          }}
        />
        {/* Simply add a read-only item as dragOverlay*/}
        {createPortal(
          <DragOverlay
            dropAnimation={dropAnimationConfig}
            modifiers={indicator ? [adjustTranslate] : undefined}
          >
            {activeId && activeItem ? (
              <SortableBuildingBlockListItem
                id={activeId}
                block={activeItem}
                flatBlocks={FlatBlocks}
                depth={0}
                childCount={getChildCount(treeBlocks, activeId) + 1}
                indentationWidth={indentationWidth}
              />
            ) : null}
          </DragOverlay>,
          document.body
        )}
      </SortableContext>
    </DndContext>
  );
};

export default BuildingBlockList;
