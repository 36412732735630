import React, { PropsWithChildren } from "react";
import { Paper } from "@mui/material";
// import { patternBlocks } from "@/css/pattern";

const Background: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Paper
      elevation={0}
      square
      sx={{
        overflow: "auto",
        flex: 1,
        bgcolor: "#242120",
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
        height: "100%",
        // ...patternBlocks,
      }}
    >
      {children}
    </Paper>
  );
};

export default Background;
