import LoadingPage from "../auth/LoadingPage";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Background from "../components/Background";

const SettingsPage = () => {
  return (
    <Background>
      <div>
        <p>Typo fix workspace wide</p>
      </div>
    </Background>
  );
};

export default withAuthenticationRequired(SettingsPage, {
  onRedirecting: () => <LoadingPage />,
});
