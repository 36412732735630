import { withAuthenticationRequired } from "@auth0/auth0-react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import FilterListIcon from "@mui/icons-material/FilterList";
import RefreshIcon from "@mui/icons-material/Refresh";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import { Box, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import debounce from "lodash.debounce";
import {
  ChangeEvent,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { BlockEditPanel } from "src/buildingBlocks/components/BlockEditPanel";
import { useCollapseUserSettings } from "src/buildingBlocks/hooks/useCollapseUserSettings";
import { useSharedBuildingBlock } from "src/buildingBlocks/hooks/useSharedBuildingBlock";
import { useWindowDimensions } from "src/utils/useWindowDimensions";
import LoadingPage from "../auth/LoadingPage";
import BuildingBlockList from "../buildingBlocks/components/BuildingBlocksList";
import { useBBMutation } from "../buildingBlocks/hooks/useBBMutation";
import { useBuildingBlocks } from "../buildingBlocks/hooks/useBuildingBlocks";
import { Block, Blocks, CollapseUserSettings } from "../buildingBlocks/types";
import Background from "../components/Background";
import {
  BlockEditingModalStateContext,
  BlockEditingModalStateProvider,
} from "./BlockEditingModalStateProvider";

const getFilterBlocks = (filter: string, blocks: Blocks) => {
  // if incomplete show incompleteblocks
  if (filter === "incomplete") {
    return blocks.filter((block) => !block.completed);
  }
  // if show completeblock
  if (filter === "complete") {
    return blocks.filter((block) => block.completed);
  }
  // complete all
  return blocks;
};

// Sort menu states
const getSortBlocks = (filter: string, blocks: Blocks) => {
  // if A-Z show blocks in A-Z order
  if (filter === "A-Z") {
    return blocks.slice().sort((a, b) => a.title.localeCompare(b.title));
  }
  // if Z-A show blocks in Z-A order
  if (filter === "Z-A") {
    return blocks.slice().sort((a, b) => b.title.localeCompare(a.title));
  }
  // complete all
  return blocks;
};

const LIST_HEIGHT_SPACE_REST = 142;

const Homepage = () => {
  const { height } = useWindowDimensions();
  // const navigate = useNavigate();
  const {
    data: buildingBlocksData,
    refetch: refetchBuildingBlocks,
    status: buildingBlocksStatus,
  } = useBuildingBlocks();
  const {
    data: sharedBuildingBlocksData,
    refetch: refetchSharedBuildingBlocks,
    status: sharedBuildingBlocksStatus,
  } = useSharedBuildingBlock();

  const refetchEverything = () => {
    refetchBuildingBlocks();
    refetchSharedBuildingBlocks();
  };

  const { data: collapseUserSettingData, status: collapseUserSettingStatus } =
    useCollapseUserSettings();

  const [ready, setReady] = useState(false);

  const mutation = useBBMutation();
  const [blocks, setBlocks] = useState<Array<Block>>([]);
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState<boolean>(false);
  const [isSortMenuOpen, setIsSortMenuOpen] = useState<boolean>(false);
  const { isBlockModalOpen, setIsBlockModalOpen, editingBlockId } = useContext(
    BlockEditingModalStateContext
  );

  const anchorEl = useRef<HTMLButtonElement>(null);
  const [filter, setFilter] = useState<string>("all");
  const [sort, setSort] = useState<string>("none");

  const handleBlockCheckChange = useMemo(
    () => (block: Block, event: ChangeEvent<HTMLInputElement>) => {
      setBlocks((bs: Array<Block>) =>
        bs.map((b: Block) =>
          b.id === block.id ? { ...block, completed: event.target.checked } : b
        )
      );
      mutation.mutate({
        id: block.id,
        completed: event.target.checked,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mutation.mutate]
  );

  const handleBlockDueChangeChange = useMemo(
    () => (block: Block, date: Date) => {
      setBlocks((bs: Array<Block>) =>
        bs.map((b: Block) =>
          b.id === block.id ? { ...block, dueDate: date } : b
        )
      );
      mutation.mutate({
        id: block.id,
        dueDate: date,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mutation.mutate]
  );

  const saveBlockChange = useMemo(() => {
    return (b: Partial<Block>) => {
      mutation.mutate(b);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutation.mutate]);

  const debouncedBlockChange = useMemo(
    () => debounce(saveBlockChange, 500),
    [saveBlockChange]
  );

  const handleBlockChange = useMemo(
    () => (id: string, changes: Partial<Block>) => {
      setBlocks((bs: Array<Block>) =>
        bs.map((b: Block) => (b.id === id ? { ...b, ...changes } : b))
      );
      debouncedBlockChange({ id, ...changes });
    },
    [debouncedBlockChange]
  );

  const handleBlockCreate = useMemo(
    () => async () => {
      await mutation.mutate(
        {
          title: "",
          content: "",
        },
        {
          onSuccess: () => refetchEverything(),
          onError: () => refetchEverything(),
          onSettled: () => refetchEverything(),
        }
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mutation.mutate]
  );

  const handleBlockRemove = useMemo(
    () => (blockId: string) => {
      mutation.mutate(
        {
          id: blockId,
          delete: true,
        },
        { onSettled: () => refetchEverything() }
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mutation.mutate, blocks]
  );

  useEffect(() => {
    setBlocks([
      ...(buildingBlocksData?.data ? buildingBlocksData?.data : []),
      ...(sharedBuildingBlocksData?.data ? sharedBuildingBlocksData?.data : []),
    ]);
  }, [buildingBlocksData, sharedBuildingBlocksData]);

  useEffect(() => {
    setReady(
      buildingBlocksStatus === "success" &&
        collapseUserSettingStatus === "success" &&
        sharedBuildingBlocksStatus === "success"
    );
  }, [
    buildingBlocksStatus,
    collapseUserSettingStatus,
    sharedBuildingBlocksStatus,
  ]);

  return (
    <Background>
      <Box>
        <Box sx={{ px: 2, pt: 1 }}>
          <Typography
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            variant="h5"
          >
            Personal
            <Box>
              <IconButton
                ref={anchorEl}
                size="small"
                color="inherit"
                onClick={() => setIsFilterMenuOpen((open) => !open)}
              >
                <FilterListIcon />
              </IconButton>
              <IconButton
                ref={anchorEl}
                size="small"
                color="inherit"
                onClick={() => setIsSortMenuOpen((open) => !open)}
              >
                <SwapVertIcon />
              </IconButton>
            </Box>
          </Typography>
        </Box>
        <Menu
          anchorEl={anchorEl.current}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={isSortMenuOpen}
          onClose={() => setIsSortMenuOpen(false)}
        >
          <MenuItem
            onClick={() => {
              setIsSortMenuOpen(false);
              setSort("none");
            }}
          >
            None
          </MenuItem>
          <MenuItem
            onClick={() => {
              setIsSortMenuOpen(false);
              setSort("A-Z");
            }}
          >
            A-Z
          </MenuItem>
          <MenuItem
            onClick={() => {
              setIsSortMenuOpen(false);
              setSort("Z-A");
            }}
          >
            Z-A
          </MenuItem>
          <MenuItem
            onClick={() => {
              setIsSortMenuOpen(false);
              // Need Due Date state
            }}
          >
            Due Date
          </MenuItem>
          <MenuItem
            onClick={() => {
              setIsSortMenuOpen(false);
              // Need set created date sorting state here
            }}
          >
            Created Date
          </MenuItem>
        </Menu>

        <Menu
          anchorEl={anchorEl.current}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={isFilterMenuOpen}
          onClose={() => setIsFilterMenuOpen(false)}
        >
          <MenuItem
            onClick={() => {
              setIsFilterMenuOpen(false);
              setFilter("incomplete");
            }}
          >
            Incomplete
          </MenuItem>
          <MenuItem
            onClick={() => {
              setIsFilterMenuOpen(false);
              setFilter("complete");
            }}
          >
            Complete
          </MenuItem>
          <MenuItem
            onClick={() => {
              setIsFilterMenuOpen(false);
              setFilter("all");
            }}
          >
            All
          </MenuItem>
        </Menu>

        {ready ? (
          <BuildingBlockList
            blocks={getSortBlocks(sort, getFilterBlocks(filter, blocks))}
            collapseUserSettings={
              collapseUserSettingData?.data as unknown as CollapseUserSettings
            }
            handleBlockChange={handleBlockChange}
            handleBlockCheckChange={handleBlockCheckChange}
            handleBlockDueChangeChange={handleBlockDueChangeChange}
            refetchBuildingBlocks={refetchEverything}
            listHeight={height - LIST_HEIGHT_SPACE_REST}
          />
        ) : null}

        <Box sx={{ px: 2 }}>
          <IconButton size="small" color="inherit" onClick={handleBlockCreate}>
            <AddCircleIcon />
          </IconButton>
          <IconButton size="small" onClick={() => refetchEverything()}>
            <RefreshIcon />
          </IconButton>
        </Box>

        <BlockEditPanel
          block={blocks?.find((b) => b.id === editingBlockId)}
          handleBlockRemove={handleBlockRemove}
          handleBlockChange={handleBlockChange}
          open={isBlockModalOpen}
          onClose={() => {
            setIsBlockModalOpen(false);
          }}
        />
      </Box>
    </Background>
  );
};

const Wrapper = () => {
  return (
    <BlockEditingModalStateProvider>
      <Homepage />
    </BlockEditingModalStateProvider>
  );
};

export default withAuthenticationRequired(Wrapper, {
  onRedirecting: () => <LoadingPage />,
});
