import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";

export const useSharedBuildingBlock = (options = {}) => {
  const { getAccessTokenSilently } = useAuth0();
  const result = useQuery(
    `buildingBlocks/else`,
    async () => {
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_REST_ENDPOINT,
      });
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      return axios(
        `${process.env.REACT_APP_REST_ENDPOINT}/api/building-blocks/else`,
        config
      );
    },
    options
  );

  return useMemo(() => result, [result]);
};
