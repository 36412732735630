import React, { ReactNode, useMemo, useState } from "react";

export const BlockEditingModalStateContext = React.createContext<{
  isBlockModalOpen: boolean;
  setIsBlockModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  editingBlockId: string | undefined;
  setEditingBlockId: React.Dispatch<React.SetStateAction<string | undefined>>;
  //@ts-ignore
}>({});

export const BlockEditingModalStateProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [isBlockModalOpen, setIsBlockModalOpen] = useState<boolean>(false);
  const [editingBlockId, setEditingBlockId] = useState<string>();

  const result = useMemo(
    () => ({
      isBlockModalOpen,
      setIsBlockModalOpen,
      editingBlockId,
      setEditingBlockId,
    }),
    [isBlockModalOpen, editingBlockId]
  );

  return (
    <BlockEditingModalStateContext.Provider value={result}>
      {children}
    </BlockEditingModalStateContext.Provider>
  );
};
