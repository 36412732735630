import { Button, Box, TextField } from "@mui/material";

import { Block } from "../buildingBlocks/types";
import { Tiptap } from "../tiptap/Tiptap";

export const ComponentDisplay = ({
  block,
  handleBlockChange,
  handleBlockRemove,
}: {
  block?: Block;
  handleBlockRemove: Function;
  handleBlockChange: (id: string, changes: Partial<Block>) => void;
}) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Box sx={{ width: 1, p: 2, maxWidth: 800 }}>
        <TextField
          value={block?.title || ""}
          sx={{ width: 1, mt: 1 }}
          label="Title"
          error={!block?.title}
          onChange={(event) => {
            if (!block?.id) return;
            handleBlockChange(block?.id, {
              title: event.target.value,
            });
          }}
        />
        <Box sx={{ height: "10px", width: "100%" }}></Box>
        <Tiptap
          content={block?.content || ""}
          onChange={(html: string) => {
            if (!block?.id) return;
            handleBlockChange(block?.id, {
              content: html,
            });
          }}
        />
        <Box sx={{ height: "10px", width: "100%" }}></Box>
        <Button
          color="warning"
          onClick={() => {
            handleBlockRemove(block?.id);
          }}
        >
          Remove
        </Button>
      </Box>
    </Box>
  );
};
