import { useRef } from "react";
import { useBBMutation } from "./useBBMutation";
import { Block } from "../types";

//! HACK: not sure when a block will get added, magically find the eventually adding blocks from a block
// TODO fix this with a better focus solution, maybe the newly created block can focus itself
/**
 * It uses the DOM tree to locate the index of what should the next be added.
 * Multiple key presses will generate more than one block, and it will only focus on the last one when all requests finishes.
 */
export const useQuickBlockCreate = () => {
  const mutation = useBBMutation();
  const expectedFutureBlockLengthRef = useRef<number>(0);
  const expectedFutureBlockIndexRef = useRef<number>(0);
  const setIntervalCancelRef = useRef<NodeJS.Timer>();

  const createRelativeBlock = async ({
    event,
    block,
    refetchBuildingBlocks,
  }: {
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>;
    block: Block;
    refetchBuildingBlocks?: Function;
  }) => {
    const targetListElement =
      // @ts-ignore
      event?.target?.parentElement?.parentElement?.parentElement?.parentElement;
    const targetListElementParent =
      // @ts-ignore
      event?.target?.parentElement?.parentElement?.parentElement?.parentElement
        ?.parentElement;

    const listElements: HTMLDListElement[] =
      targetListElementParent?.querySelectorAll("li");

    const currentBlockIndex = Array.prototype.indexOf.call(
      listElements,
      targetListElement
    );

    const currentBlockslength = listElements.length;

    if (expectedFutureBlockLengthRef.current === 0) {
      expectedFutureBlockLengthRef.current = currentBlockslength + 1;
    } else {
      expectedFutureBlockLengthRef.current =
        expectedFutureBlockLengthRef.current + 1;
    }

    // First click
    if (expectedFutureBlockIndexRef.current === 0) {
      let newBlockDistance = 1;

      const currentStyle = listElements[currentBlockIndex].style.cssText;
      const nextStyle =
        // If currentBlock is not the last block
        listElements.length - 1 > currentBlockIndex
          ? listElements[currentBlockIndex + 1].style.cssText
          : "impossible";

      // If the next block sits below
      if (
        currentStyle !== nextStyle &&
        !currentStyle.localeCompare(nextStyle)
      ) {
        const newBlockProjectedIndex = Array.prototype.findIndex.call(
          listElements,
          (li, index) => {
            if (index > currentBlockIndex && li.style.cssText === currentStyle)
              return true;
            return false;
          }
        );

        if (newBlockProjectedIndex) {
          newBlockDistance = newBlockProjectedIndex - currentBlockIndex;
        }
      }

      expectedFutureBlockIndexRef.current =
        currentBlockIndex + newBlockDistance;
    } else {
      expectedFutureBlockIndexRef.current =
        expectedFutureBlockIndexRef.current + 1;
    }

    clearInterval(setIntervalCancelRef.current);

    const cancel = setInterval(() => {
      if (
        expectedFutureBlockLengthRef.current ===
        targetListElementParent.querySelectorAll("li").length
      ) {
        targetListElementParent
          .querySelectorAll("li")
          [expectedFutureBlockIndexRef.current]?.querySelector?.(
            "input[type=text]"
          )
          ?.focus();

        clearInterval(cancel);
        expectedFutureBlockLengthRef.current = 0;
        expectedFutureBlockIndexRef.current = 0;
      }
    }, 100);

    setIntervalCancelRef.current = cancel;

    mutation.mutate(
      {
        title: "",
        content: "",
        targetId: block.id,
      },
      {
        onSuccess: async () => {
          refetchBuildingBlocks && refetchBuildingBlocks();
        },
        onError: () => refetchBuildingBlocks && refetchBuildingBlocks(),
        onSettled: () => refetchBuildingBlocks && refetchBuildingBlocks(),
      }
    );
  };

  return createRelativeBlock;
};
