import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@mui/material";

const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <>
      <Button
        variant="outlined"
        size="large"
        onClick={() => logout({ returnTo: window.location.origin })}
        sx={{
          mt: 4,
        }}
      >
        Log Out
      </Button>
    </>
  );
};

export default LogoutButton;
