import { grey } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";
import type {} from "@mui/x-date-pickers/themeAugmentation";

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#FAF3DD",
    },
    secondary: {
      main: "#71d6ed",
    },
    text: {
      primary: "#F8F0E3",
      secondary: grey[500],
    },
    error: {
      main: "#AA4A44",
    },
  },
});

export default theme;
