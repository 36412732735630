import { TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";

export const DateTimePicker = ({
  open,
  date,
  onChange,
  onClose,
  onOpen,
}: {
  open: boolean;
  date?: Date;
  onChange: (value: Date | null, keyboardInputValue?: string) => void;
  onClose: () => void;
  onOpen: () => void;
}) => {
  return (
    <DesktopDatePicker
      label="Date desktop"
      inputFormat="dd/MM/yyyy"
      value={date}
      onChange={onChange}
      onClose={onClose}
      onOpen={onOpen}
      open={open}
      renderInput={(params) => (
        <TextField
          sx={{ maxWidth: "124px" }}
          {...params}
          label={undefined}
          variant="standard"
        />
      )}
    />
  );
};
