import React, { useCallback } from "react";

import { Editor, EditorContent, useEditor } from "@tiptap/react";
import Placeholder from "@tiptap/extension-placeholder";
import StarterKit from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";

import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatStrikethroughIcon from "@mui/icons-material/FormatStrikethrough";
import CodeIcon from "@mui/icons-material/Code";
import FormatClearIcon from "@mui/icons-material/FormatClear";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import DataObjectIcon from "@mui/icons-material/DataObject";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import RedoIcon from "@mui/icons-material/Redo";
import UndoIcon from "@mui/icons-material/Undo";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import LinkOffIcon from "@mui/icons-material/LinkOff";

// Styles for the editor, globally
import "./styles.css";
import { Box, Divider, IconButton, useTheme } from "@mui/material";

const MenuBar = ({ editor }: { editor: Editor | null }) => {
  const setLink = useCallback(() => {
    if (!editor) {
      return null;
    }

    const previousUrl = editor.getAttributes("link").href;
    const url = window.prompt("URL", previousUrl);

    // cancelled
    if (url === null) {
      return;
    }

    // empty
    if (url === "") {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();

      return;
    }

    // update link
    editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
  }, [editor]);

  if (!editor) {
    return null;
  }

  return (
    <>
      <IconButton
        size="small"
        color="inherit"
        onClick={() => editor.chain().focus().toggleBold().run()}
      >
        <FormatBoldIcon
          color={editor.isActive("bold") ? "primary" : "inherit"}
        />
      </IconButton>
      <IconButton
        size="small"
        color="inherit"
        onClick={() => editor.chain().focus().toggleItalic().run()}
      >
        <FormatItalicIcon
          color={editor.isActive("italic") ? "primary" : "inherit"}
        />
      </IconButton>
      <IconButton
        size="small"
        color="inherit"
        onClick={() => editor.chain().focus().toggleStrike().run()}
      >
        <FormatStrikethroughIcon
          color={editor.isActive("strike") ? "primary" : "inherit"}
        />
      </IconButton>

      <IconButton
        size="small"
        color="inherit"
        onClick={() => editor.chain().focus().toggleCode().run()}
      >
        <CodeIcon color={editor.isActive("code") ? "primary" : "inherit"} />
      </IconButton>
      <IconButton
        size="small"
        color="inherit"
        onClick={() => editor.chain().focus().unsetAllMarks().run()}
      >
        <FormatClearIcon />
      </IconButton>
      <Divider
        orientation="vertical"
        variant="middle"
        sx={{ display: "inline", marginLeft: "4px", marginRight: "4px" }}
      />
      <button
        onClick={() => editor.chain().focus().setParagraph().run()}
        className={editor.isActive("paragraph") ? "is-active" : ""}
      >
        paragraph
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        className={editor.isActive("heading", { level: 1 }) ? "is-active" : ""}
      >
        h1
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        className={editor.isActive("heading", { level: 2 }) ? "is-active" : ""}
      >
        h2
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
        className={editor.isActive("heading", { level: 3 }) ? "is-active" : ""}
      >
        h3
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
        className={editor.isActive("heading", { level: 4 }) ? "is-active" : ""}
      >
        h4
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
        className={editor.isActive("heading", { level: 5 }) ? "is-active" : ""}
      >
        h5
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
        className={editor.isActive("heading", { level: 6 }) ? "is-active" : ""}
      >
        h6
      </button>
      <Divider
        orientation="vertical"
        variant="middle"
        sx={{ display: "inline", marginLeft: "4px", marginRight: "4px" }}
      />
      <IconButton
        size="small"
        color="inherit"
        onClick={() => editor.chain().focus().toggleBulletList().run()}
      >
        <FormatListBulletedIcon
          color={editor.isActive("bulletList") ? "primary" : "inherit"}
        />
      </IconButton>
      <IconButton
        size="small"
        color="inherit"
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
      >
        <FormatListNumberedIcon
          color={editor.isActive("orderedList") ? "primary" : "inherit"}
        />
      </IconButton>
      <IconButton
        size="small"
        color="inherit"
        onClick={() => editor.chain().focus().toggleCodeBlock().run()}
      >
        {/* code block toggle*/}
        <DataObjectIcon
          color={editor.isActive("codeBlock") ? "primary" : "inherit"}
        />
      </IconButton>
      <IconButton
        size="small"
        color="inherit"
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
      >
        <FormatQuoteIcon
          color={editor.isActive("blockquote") ? "primary" : "inherit"}
        />
      </IconButton>
      <IconButton
        size="small"
        color="inherit"
        onClick={() => editor.chain().focus().clearNodes().run()}
      >
        {/* clear nodes*/}
        <FormatClearIcon />
      </IconButton>
      <Divider
        orientation="vertical"
        variant="middle"
        sx={{ display: "inline", marginLeft: "4px", marginRight: "4px" }}
      />
      <IconButton
        size="small"
        color="inherit"
        onClick={() => editor.chain().focus().setHorizontalRule().run()}
      >
        <HorizontalRuleIcon />
      </IconButton>
      <IconButton size="small" color="inherit" onClick={setLink}>
        <InsertLinkIcon
          color={editor.isActive("link") ? "primary" : "inherit"}
        />
      </IconButton>
      <IconButton
        size="small"
        color="inherit"
        onClick={() => editor.chain().focus().unsetLink().run()}
        disabled={!editor.isActive("link")}
      >
        <LinkOffIcon />
      </IconButton>
      <Divider
        orientation="vertical"
        variant="middle"
        sx={{ display: "inline", marginLeft: "4px", marginRight: "4px" }}
      />
      <IconButton
        size="small"
        color="inherit"
        onClick={() => editor.chain().focus().undo().run()}
      >
        <UndoIcon />
      </IconButton>
      <IconButton
        size="small"
        color="inherit"
        onClick={() => editor.chain().focus().redo().run()}
      >
        <RedoIcon />
      </IconButton>
    </>
  );
};

export const Tiptap = ({
  content,
  onChange,
}: {
  content: string | undefined;
  onChange: Function;
}) => {
  // This editor will not receive React updates, any dynamic updates should be called manually on the editor instace
  const editor = useEditor({
    extensions: [
      StarterKit,
      Placeholder.configure({
        placeholder: "Type something here ...",
      }),
      Link.configure({
        protocols: ["ftp", "mailto"],
      }),
    ],
    content,
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      onChange(html);
    },
  });
  const theme = useTheme();

  return (
    <div>
      <MenuBar editor={editor} />
      <Box
        sx={{
          height: "10px",
          width: "100%",
          ".ProseMirror": {
            borderColor: theme.palette.text.primary,
          },
        }}
      ></Box>
      <EditorContent editor={editor} />
    </div>
  );
};
