import { useAuth0 } from "@auth0/auth0-react";
import axios, { AxiosResponse } from "axios";
import React, { PropsWithChildren, useContext, useMemo } from "react";
import { useQuery } from "react-query";
import { User } from "./types";

type MeContextValue = {
  isMeLoading: boolean;
  meError: unknown;
  meData: AxiosResponse<User, any> | undefined;
};

export const MeContext = React.createContext<MeContextValue>({
  isMeLoading: true,
  meError: new Error("init"),
  meData: undefined,
});

export const MeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();

  const result = useQuery("me", async () => {
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_REST_ENDPOINT,
      scope: "read:posts",
    });
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    return axios(`${process.env.REACT_APP_REST_ENDPOINT}/api/users/me`, config);
  });

  const value = useMemo(
    () => ({
      isMeLoading: result.isLoading,
      meError: result.error,
      meData: result.data,
    }),
    [result]
  );

  return <MeContext.Provider value={value}>{children}</MeContext.Provider>;
};

export const useMe = () => {
  return useContext<MeContextValue>(MeContext);
};
