import LoadingPage from "../auth/LoadingPage";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Background from "../components/Background";

const ProfilePage = () => {
  return (
    <Background>
      <div>
        <p>Profile Page</p>
      </div>
    </Background>
  );
};

export default withAuthenticationRequired(ProfilePage, {
  onRedirecting: () => <LoadingPage />,
});
