import { AppState, Auth0Provider } from "@auth0/auth0-react";
import React, { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";

export const Auth0ProviderWithNavigate: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState: AppState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  const auth0Config = {
    domain: "jido.au.auth0.com",
    clientId: "kiK691fnB1aGzWyZ3Ejk3jWU6eT9rSvf",
    redirectUri: `${window.location.origin}`,
    audience: `${process.env.REACT_APP_REST_ENDPOINT}`, // Our API
    scope: "read:current_user update:current_user_metadata", // default
    useRefreshTokens: true,
    onRedirectCallback: onRedirectCallback,
  };

  return <Auth0Provider {...auth0Config}>{children}</Auth0Provider>;
};
