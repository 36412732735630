import ArticleIcon from "@mui/icons-material/Article";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IconButton, Input, Typography, useTheme } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { Box } from "@mui/system";
import React, {
  HTMLAttributes,
  forwardRef,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
// import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CircleCheckedFilled from "@mui/icons-material/CheckCircle";
import FilterListIcon from "@mui/icons-material/FilterList";
import CircleUnchecked from "@mui/icons-material/RadioButtonUnchecked";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import { DebouncedFunc } from "lodash";
import debounce from "lodash.debounce";
import { DateTimePicker } from "src/components/DateTimePicker";
import { BlockEditingModalStateContext } from "src/home/BlockEditingModalStateProvider";
import { useQuickBlockCreate } from "../hooks/useQuickBlockCreate";
import { Block, Blocks } from "../types";

export interface BuildingBlockListItemProps
  extends Omit<HTMLAttributes<HTMLLIElement>, "id"> {
  block: Block;
  flatBlocks: Blocks;
  depth: number;
  childCount?: number;
  clone?: boolean;
  collapsed?: boolean;
  disableInteraction?: boolean;
  disableSelection?: boolean;
  ghost?: boolean;
  handleProps?: any;
  indicator?: boolean;
  indentationWidth: number;
  handleBlockChange?: Function;
  handleBlockCheckChange?: Function;
  handleBlockDueChangeChange?: Function;
  refetchBuildingBlocks?: Function;
  onCollapse?(): void;
  onRemove?(): void;
  wrapperRef?(node: HTMLLIElement): void;
}

export interface BuildingBlockListItemFullProps
  extends BuildingBlockListItemProps {
  isDragging: boolean;
  isSorting: boolean;
}

export const BuildingBlockListItem = forwardRef<
  HTMLDivElement,
  BuildingBlockListItemFullProps
>(
  (
    {
      childCount,
      clone,
      depth,
      disableSelection,
      disableInteraction,
      ghost,
      handleProps,
      indentationWidth,
      indicator,
      collapsed,
      onCollapse,
      onRemove,
      style,
      wrapperRef,
      block,
      flatBlocks,
      handleBlockChange,
      handleBlockCheckChange,
      handleBlockDueChangeChange,
      refetchBuildingBlocks,
      isDragging,
      isSorting,
      ...props
    },
    ref
  ) => {
    const theme = useTheme();

    const [isOpenDateTimePicker, setIsOpenDateTimePicker] =
      useState<boolean>(false);

    const { setIsBlockModalOpen, setEditingBlockId } = useContext(
      BlockEditingModalStateContext
    );

    const createRelativeBlock = useQuickBlockCreate();

    const [title, setTitle] = useState(block.title);
    const titleDebounceRef = useRef<DebouncedFunc<() => void>>();

    const saveTitleChange = useMemo(() => {
      return (title: string) => {
        handleBlockChange && handleBlockChange(block.id, { title: title });
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleBlockChange]);

    const debouncedTitleChange = useMemo(
      () => debounce(saveTitleChange, 500),
      [saveTitleChange]
    );

    const handleTitleChange = useMemo(
      () =>
        (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          titleDebounceRef.current?.cancel();
          setTitle(event.target.value);
          debouncedTitleChange(event.target.value);
        },
      [setTitle, debouncedTitleChange]
    );

    const debouncedBlockTitleUpdate = useMemo(
      () =>
        debounce(() => {
          setTitle(block.title);
        }, 500),
      [setTitle, block.title]
    );

    useEffect(() => {
      if (block.title !== title) {
        titleDebounceRef.current?.cancel();
        titleDebounceRef.current = debouncedBlockTitleUpdate;
        titleDebounceRef.current();
      }
      // eslint-disable-next-line
    }, [block]);
    const blockIndex = flatBlocks.findIndex((b) => b.id === block.id);
    const isFromADifferentSpace =
      blockIndex > 0
        ? flatBlocks[blockIndex].ownerId !== flatBlocks[blockIndex - 1].ownerId
        : false;

    // @ts-expect-error
    const hasChildren = flatBlocks[blockIndex]?.children?.length;

    return (
      <li
        ref={wrapperRef}
        style={
          {
            ...style,
            paddingLeft: `${indentationWidth * depth}px`,
            listStyleType: "none",
          } as React.CSSProperties
        }
        {...props}
      >
        {isFromADifferentSpace ? (
          <Box
            sx={{
              px: 2,
              pt: 1,
              borderTop: "#808080 4px solid",
            }}
          >
            <Typography
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              variant="h5"
            >
              {flatBlocks[blockIndex].ownerId}
              <Box>
                <IconButton
                  // ref={anchorEl}
                  size="small"
                  color="inherit"
                  // onClick={() => setIsFilterMenuOpen((open) => !open)}
                >
                  <FilterListIcon />
                </IconButton>
                <IconButton
                  // ref={anchorEl}
                  size="small"
                  color="inherit"
                  // onClick={() => setIsSortMenuOpen((open) => !open)}
                >
                  <SwapVertIcon />
                </IconButton>
              </Box>
            </Typography>
          </Box>
        ) : null}
        <Box
          ref={ref}
          sx={[
            {
              // TODO: Fix this with actual scrollbar detection? CSS?
              width: "calc(100% - 12px)",
              display: "flex",
              alignItems: "center",
              opacity: isDragging ? ".5" : "1",
              pointerEvents: isSorting ? "none" : "auto",
              button: {
                color: theme.palette.text.primary,
              },
            },
            {
              "& > svg:first-of-type": {
                opacity: 0,
              },
            },
            {
              "&:hover > svg:first-of-type": {
                opacity: isFromADifferentSpace ? 0 : 1,
              },
            },
          ]}
        >
          <DragIndicatorIcon
            sx={{
              height: 24,
              width: 24,
              marginRight: "-3px",
              touchAction: "none",
              color: "#F8F0E3",
            }}
            {...(isFromADifferentSpace ? {} : handleProps)}
          />
          <Box
            sx={{
              height: "38px",
              width: "1px",
              backgroundColor: "#F8F0E3",
              overflowX: "visible",
              marginTop: "-10px",
              marginBottom: "-10px",
            }}
          ></Box>
          <ExpandMoreIcon
            sx={{
              height: 24,
              width: 24,
              marginLeft: "3px",
              marginRight: "-3px",
              /***
               * This fixed a bug where a touch device can "penetrate" and bind a scrolling effect to the drag handle
               */
              touchAction: "none",
              color: "#F8F0E3",
              transform: collapsed ? "rotate(-90deg)" : "",
              opacity: hasChildren ? 1 : 0,
            }}
            onClick={onCollapse}
          />
          <Checkbox
            icon={<CircleUnchecked />}
            checkedIcon={<CircleCheckedFilled />}
            color="default"
            sx={{
              color: theme.palette.text.primary,
              "&.Mui-checked": {
                color: theme.palette.text.primary,
              },
              padding: "3px",
            }}
            checked={block.completed}
            size="small"
            onChange={(event) =>
              // TODO fix this please, handle should be a valid sub component
              handleBlockCheckChange && handleBlockCheckChange(block, event)
            }
          />
          <Box sx={{ width: 1 }}>
            <Input
              value={title}
              ref={ref}
              sx={[
                { width: 1 },
                {
                  "&:hover:not(.Mui-disabled):before": {
                    borderBottom: "2px solid #474747",
                  },
                },
                {
                  "&:before": {
                    borderBottom: "1px solid #474747",
                  },
                },
                {
                  "&:after": {
                    borderBottom: "2px solid #646464",
                  },
                },
              ]}
              error={!block?.title}
              onChange={handleTitleChange}
              onKeyDown={(event) => {
                if (event.code === "Enter")
                  createRelativeBlock({ event, block, refetchBuildingBlocks });
              }}
            />
          </Box>

          <IconButton
            size="small"
            onClick={() => {
              setEditingBlockId(block.id);
              setIsBlockModalOpen(true);
            }}
            sx={{
              color: theme.palette.text.primary,
            }}
          >
            <ArticleIcon />
          </IconButton>
          <DateTimePicker
            open={isOpenDateTimePicker}
            onClose={() => setIsOpenDateTimePicker(false)}
            onOpen={() => setIsOpenDateTimePicker(true)}
            onChange={(value: Date | null, keyboardInputValue?: string) => {
              // TODO fix this please, handle should be a valid sub component
              handleBlockDueChangeChange &&
                handleBlockDueChangeChange(block, value);
              block.dueDate = value ?? null;
            }}
            date={block.dueDate || undefined}
          />
        </Box>
      </li>
    );
  }
);
