import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useMemo } from "react";
import { useMutation, useQuery } from "react-query";
import { CollapseUserSetting } from "../types";

export const useCollapseUserSettings = () => {
  const { getAccessTokenSilently } = useAuth0();
  const result = useQuery(`collapseUserSettings`, async () => {
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_REST_ENDPOINT,
    });
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    return axios(
      `${process.env.REACT_APP_REST_ENDPOINT}/api/collapse-user-settings`,
      config
    );
  });

  return useMemo(() => result, [result]);
};

export const useCollapseUserSettingsCreate = () => {
  const { getAccessTokenSilently } = useAuth0();

  return useMutation(
    async (collapseUserSetting: Partial<CollapseUserSetting>) => {
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_REST_ENDPOINT,
      });

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const endpoint = `${process.env.REACT_APP_REST_ENDPOINT}/api/collapse-user-settings`;

      return axios.post(
        `${endpoint}`,
        { blockId: collapseUserSetting.blockId },
        config
      );
    }
  );
};

export const useCollapseUserSettingsDelete = () => {
  const { getAccessTokenSilently } = useAuth0();

  return useMutation(
    async (collapseUserSetting: Partial<CollapseUserSetting>) => {
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_REST_ENDPOINT,
      });

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const endpoint = `${process.env.REACT_APP_REST_ENDPOINT}/api/collapse-user-settings/${collapseUserSetting.blockId}`;

      return axios.delete(`${endpoint}`, config);
    }
  );
};
