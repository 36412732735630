import { AnimateLayoutChanges, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { CSSProperties } from "react";
import { Blocks } from "../types";
import {
  BuildingBlockListItem,
  BuildingBlockListItemProps,
} from "./BuildingBlockListItem";
import { UniqueIdentifier, iOS } from "./utils";

const animateLayoutChanges: AnimateLayoutChanges = ({
  isSorting,
  wasDragging,
}) => (isSorting || wasDragging ? false : true);

export interface SortableBuildingBlockListItemProps
  extends BuildingBlockListItemProps {
  id: UniqueIdentifier;
  flatBlocks: Blocks;
}

export function SortableBuildingBlockListItem({
  id,
  depth,
  style,
  ...props
}: SortableBuildingBlockListItemProps) {
  const {
    attributes,
    isDragging,
    isSorting,
    listeners,
    setDraggableNodeRef,
    setDroppableNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
    animateLayoutChanges,
  });

  const transformStyle: CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <BuildingBlockListItem
      ref={setDraggableNodeRef}
      wrapperRef={setDroppableNodeRef}
      style={{ ...style, ...transformStyle }}
      depth={depth}
      ghost={isDragging}
      disableSelection={iOS}
      disableInteraction={isSorting}
      isDragging={isDragging}
      isSorting={isSorting}
      handleProps={{
        ...attributes,
        ...listeners,
      }}
      {...props}
    />
  );
}
