import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@mui/material";

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <>
      <Button
        variant="outlined"
        size="large"
        color="primary"
        onClick={() => loginWithRedirect()}
        sx={{ mt: 4 }}
      >
        Log In
      </Button>
    </>
  );
};

export default LoginButton;
