import { useAuth0 } from "@auth0/auth0-react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import LogoutButton from "src/auth/LogoutButton";
import packageJSON from "../../package.json";
import LoginButton from "../auth/LoginButton";
import Background from "../components/Background";

const versionText = `v${packageJSON.version}`;

const MainPage = (): JSX.Element => {
  const { isAuthenticated, error } = useAuth0();
  const [searchParams] = useSearchParams();
  const nav = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      if (!searchParams.get("code") && !error && isAuthenticated) {
        nav("/home");
      }
    }, 200);
  }, [searchParams, error, isAuthenticated, nav]);

  return (
    <Background>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h1">Jido</Typography>
        <Typography variant="h5">{versionText}</Typography>
        {error && (
          <>
            {error.message}
            <LogoutButton />
          </>
        )}
        {!error && !isAuthenticated && <LoginButton />}
      </Box>
    </Background>
  );
};

export default MainPage;
