import { useMutation } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { Block } from "../types";

export const useBBMutation = () => {
  const { getAccessTokenSilently } = useAuth0();

  return useMutation(async (buildingBlock: Partial<Block>) => {
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_REST_ENDPOINT,
    });
    const data = {
      content: buildingBlock.content,
      title: buildingBlock.title,
      completed: buildingBlock.completed,
      dueDate: buildingBlock.dueDate,
    };
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const endpoint = `${process.env.REACT_APP_REST_ENDPOINT}/api/building-blocks`;

    // TODO: Need better pattern
    if (buildingBlock.reorder) {
      return axios.put(
        `${endpoint}/${buildingBlock.id}/reorder`,
        { targetId: buildingBlock.targetId, parentId: buildingBlock.parentId },
        config
      );
    }

    if (buildingBlock.delete) {
      return axios.delete(`${endpoint}/${buildingBlock.id}`, config);
    }

    if (buildingBlock.id) {
      return axios.put(`${endpoint}/${buildingBlock.id}`, data, config);
    }

    return axios.post(
      `${endpoint}`,
      { ...data, targetId: buildingBlock.targetId },
      config
    );
  });
};
