import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useMemo } from "react";

export const useBuildingBlocks = (suffix?: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const result = useQuery(`buildingBlocks${suffix || ""}`, async () => {
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_REST_ENDPOINT,
    });
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    return axios(
      `${process.env.REACT_APP_REST_ENDPOINT}/api/building-blocks${
        suffix || ""
      }`,
      config
    );
  });

  return useMemo(() => result, [result]);
};
