import { forwardRef } from "react";
import { Box, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import LinkIcon from '@mui/icons-material/Link';
import Slide from "@mui/material/Slide";
import { ComponentDisplay } from "src/components/ComponentDisplay";
import { Block } from "../../buildingBlocks/types";
import Background from "src/components/Background";

const Transition = forwardRef(function Transition(props, ref) {
  // @ts-ignore
  return <Slide direction="left" ref={ref} {...props} />;
});

export const BlockEditPanel = ({
  open,
  onClose,
  block,
  handleBlockRemove,
  handleBlockChange,
}: {
  block?: Block;
  open: boolean;
  onClose: () => void;
  handleBlockRemove: Function;
  handleBlockChange: (id: string, changes: Partial<Block>) => void;
}) => {
  return (
    <Dialog
      keepMounted={false}
      sx={{
        justifyContent: "flex-end",
        height: "100%",
      }}
      fullScreen
      fullWidth
      PaperProps={{
        square: true,
        sx: {
          margin: 0,
          marginLeft: "auto",
          maxWidth: "700px",
        },
      }}
      open={open}
      onClose={onClose}
      // @ts-ignore
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Button color="primary" onClick={() => {}}>
            Mark complete
          </Button>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              edge="start"
              color="inherit"
              style={{ marginRight: "4px" }}
            >
              <LinkIcon/>
            </IconButton>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Box sx={{ width: "100%", flex: 1 }}>
        <Background>
          <ComponentDisplay
            block={block}
            handleBlockChange={handleBlockChange}
            handleBlockRemove={(id: string) => {
              handleBlockRemove(id);
              onClose();
            }}
          />
        </Background>
      </Box>
    </Dialog>
  );
};
