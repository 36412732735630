import { useEffect } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import theme from "../theme";
import { ThemeProvider } from "@mui/system";
import HomePage from "../home/HomePage";
import MainPage from "../app/MainPage";
import TestPage from "../test/TestPage";
import PrimaryAppBar from "../components/PrimaryAppBar";
import { MeProvider } from "../auth/useMe";
import SettingsPage from "../settings/SettingsPage";
import ProfilePage from "../profile/ProfilePage";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { QueryClient, QueryClientProvider } from "react-query";
import { Auth0ProviderWithNavigate } from "./auth0-provider-with-navigate";
import "./globalStyles.css";

const queryClient = new QueryClient();

const serviceURL = new URL(process.env.REACT_APP_APP_DOMAIN || "");

function App() {
  // !Important, jido.io -> www.jido.io, hack
  useEffect(() => {
    if (window.location.host !== serviceURL.host) {
      window.location.host = serviceURL.host;
    }
  }, []);

  if (window.location.host !== serviceURL.host) {
    return null;
  }

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <BrowserRouter>
              <Auth0ProviderWithNavigate>
                <MeProvider>
                  <Routes>
                    <Route path="/" element={<MainPage />} />
                    <Route path="/test" element={<TestPage />} />
                    <Route element={<PrimaryAppBar />}>
                      <Route path="/home" element={<HomePage />} />
                      <Route path="/profile" element={<ProfilePage />} />
                      <Route path="/settings" element={<SettingsPage />} />
                    </Route>
                  </Routes>
                </MeProvider>
              </Auth0ProviderWithNavigate>
            </BrowserRouter>
          </LocalizationProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;
